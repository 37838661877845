.StatusCard__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.StatusCard__wrapper .group-title {
  margin-bottom: 4.5rem;
}

.StatusCard__wrapper .status-card {
  width: 320px;
  border-radius: 30px;
  padding: 16px 10px 26px;
  background-color: var(--white);
}

.StatusCard__wrapper .status-card .footer-content,
.StatusCard__wrapper .status-card .footer-title {
  margin-left: 10px;
  margin-right: 10px;
}

.StatusCard__wrapper .status-card .footer-content div:nth-child(1) {
  align-self: flex-end;
}

.StatusCard__wrapper .status-card .footer-content div:nth-child(2) {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--black);
}

.StatusCard__wrapper .status-card .footer-title {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--light-grey);
}
