.SearchForm__wrapper.flex-row {
  border-radius: 10px;
  background-color: var(--white);
  padding: 8px;
  margin-bottom: 16px;
}

@media (max-width: 640px) {
  .SearchForm__wrapper.flex-row {
    display: block;
    height: 42px;
    margin-bottom: 96px;
  }
}

.SearchForm__wrapper .input-wrapper {
  flex-grow: 1;
  gap: 0;
}

@media (max-width: 640px) {
  .SearchForm__wrapper .submit-button {
    width: 100%;
    height: 58px;
    padding: 14px;
    border-radius: 5px;
    margin-top: 43px;
  }
}
