.StatusGroup__wrapper .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-rows: auto;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.StatusGroup__wrapper .content > * {
  margin: 0 auto;
}

@media (max-width: 640px) {
  .StatusGroup__wrapper .content {
    justify-content: center;
  }
}
