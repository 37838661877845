header.STHeader__wrapper {
  position: relative;
}

.STHeader__wrapper .logo {
  flex-grow: 1;
}

.STHeader__wrapper .link {
  position: absolute;
  bottom: -4rem;
  right: 0;
  padding: 1.0625em 0.625rem;
  border-radius: 0.625rem;
  text-decoration: none;
  color: var(--black);
  background-color: var(--white);
}

@media (max-width: 640px) {
  .STHeader__wrapper .link {
    position: relative;
    bottom: auto;
    right: auto;
  }
}

.STHeader__wrapper .link:hover {
  box-shadow: var(--shadow);
}

.STHeader__wrapper .link:active {
  color: var(--black);
}
