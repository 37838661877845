.STSelect__wrapper {
  position: relative;
}

.STSelect__icon_wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.STSelect__wrapper .ant-select .ant-select-selector {
  padding-left: calc(3rem - 8px);
}
