.Website {
  background-color: var(--white);
  min-height: 100vh;
}

section {
  padding: 2.8125rem 1.3125rem;
}

section.about button {
  display: flex;
  column-gap: .3125rem;
  align-items: center;
  justify-content: center;
}

section.about .text {
  color: var(--grey);
  font-weight: 300;
  line-height: 1.5625rem;
  letter-spacing: -0.05em;
}

section.about{
  display: grid;
  margin-top: 1.625rem;
}

.about .grid{
  margin-bottom: 3.4375rem;
  max-width: 80rem;
  margin: 0 auto;
}

.about .grid h1.header{
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 3.75rem;
  letter-spacing: -0.07em;
  text-align: left;
}

.about h2{
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: -0.07em;
  text-align: center;
}

.about button {
  width: 100%;
  height: 3.125rem;
  margin: 3.4375rem 0;
}

.about .dashboard-image{
  max-width: 42.0625rem;
  width: 100%;
  height: 22.125rem;
  margin: 0 auto;
}

section.testimonials {
  background-color: var(--accent);
  overflow: hidden; 
  position: relative;
}

section.testimonials::before {
  position: absolute;
  content: "";
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: repeating-linear-gradient(
      -55deg,
      var(--accent-300) 20%,
      var(--accent-300) 30%,
      transparent 30%,         
      transparent 30.5%          
    );
  opacity: .2;
  pointer-events: none;
}


@media screen and (min-width: 1024px) {
  section {
    padding: 0 4.1875rem;
  }

  section.about{
    margin-top: 4.3125rem;
  }

  section.about .grid h1.header{
    font-size: 6.25rem;
    line-height: 6.25rem;
    margin: 0;
  }

  section.about .text {
    color: var(--grey);
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  section.faqs{
    margin: 9.6875rem calc(16.25rem - 4.1875rem) 11.5625rem calc(16.25rem - 4.1875rem);
  }

  section.testimonials{
    padding: 8.125rem 0;
  }

  .about .grid{
    display: grid;
    gap: 5.4375rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 11.6875rem;
  }

  .about button {
    width: 11.875rem;
    height: 3.4375rem;
    margin: 3.125rem 0;
  }

  .about .dashboard-image{
    max-width: 63.9375rem;
    height: 33.8125rem;
    justify-self: center;
    clip-path: inset(0 0 25% 0);
  }
}

